import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useForm, ValidationError } from '@formspree/react';
import '../App.css';

function JoinTeamModal({ show, handleClose }) {
    const [state, handleSubmit] = useForm("mwpepraq");

    if (state.succeeded) {
        return (
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton className="custom-modal-header">
                    <Modal.Title className="ada-font">Join Our Team</Modal.Title>
                </Modal.Header>
                <Modal.Body className="custom-modal-body ada-font">
                    <p>Thanks for your interest in joining our team!</p>
                    <Button variant="primary" onClick={handleClose} className="custom-submit-button ada-font">
                        Close
                    </Button>
                </Modal.Body>
            </Modal>
        );
    }

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton className="custom-modal-header">
                <Modal.Title className="ada-font">Join Our Team</Modal.Title>
            </Modal.Header>
            <Modal.Body className="custom-modal-body">
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formName" className="mb-4">
                        <Form.Label className="ada-font form-label">Name</Form.Label>
                        <Form.Control type="text" name="name" placeholder="Enter your name" required className="custom-input" />
                        <ValidationError prefix="Name" field="name" errors={state.errors} />
                    </Form.Group>
                    <Form.Group controlId="formEmail" className="mb-4">
                        <Form.Label className="ada-font form-label">Email</Form.Label>
                        <Form.Control type="email" name="email" placeholder="Enter your email" required className="custom-input" />
                        <ValidationError prefix="Email" field="email" errors={state.errors} />
                    </Form.Group>
                    <Form.Group controlId="formLinkedIn" className="mb-4">
                        <Form.Label className="ada-font form-label">LinkedIn Profile</Form.Label>
                        <Form.Control type="url" name="linkedin" placeholder="Enter your LinkedIn profile URL" required className="custom-input" />
                        <ValidationError prefix="LinkedIn" field="linkedin" errors={state.errors} />
                    </Form.Group>
                    <Form.Group controlId="formFuture" className="mb-4">
                        <Form.Label className="ada-font form-label">How do you think Ada will change Software Engineering & Infrastructure operations in the future?</Form.Label>
                        <Form.Control as="textarea" name="future" rows={3} placeholder="Enter your thoughts" className="custom-input" />
                        <ValidationError prefix="Future" field="future" errors={state.errors} />
                    </Form.Group>
                    <Button variant="primary" type="submit" disabled={state.submitting} className="custom-submit-button ada-font">
                        Submit
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default JoinTeamModal;
