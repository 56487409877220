import React, { useState } from 'react';
import { Container, Row, Col, Navbar, Button } from 'react-bootstrap';
import WaitlistModal from './components/WaitlistModal';
import JoinTeamModal from './components/JoinTeamModal';
import './App.css';

function App() {
    const [waitlistModalShow, setWaitlistModalShow] = useState(false);
    const [joinTeamModalShow, setJoinTeamModalShow] = useState(false);

    const handleWaitlistShow = () => setWaitlistModalShow(true);
    const handleWaitlistClose = () => setWaitlistModalShow(false);

    const handleJoinTeamShow = () => setJoinTeamModalShow(true);
    const handleJoinTeamClose = () => setJoinTeamModalShow(false);

    return (
        <div className="App bg-dark">
            <header className="ada-font top-header">
                <Navbar bg="dark" variant="dark" expand="lg" fixed="top">
                    <Container>
                        <Navbar.Brand href="#">
                            <img
                                src={`${process.env.PUBLIC_URL}/logo.png`}
                                width="30"
                                height="30"
                                className="d-inline-block align-top navbar-logo"
                                alt="Ada Labs logo"
                            />
                            {' '}
                            Ada Labs
                        </Navbar.Brand>
                    </Container>
                </Navbar>
            </header>
            <section className="py-10 bg-dark">
                <Container>
                    <Row className="align-items-center">
                        <Col md={5} className="text-white py-5 ada-font">
                            <h2 className="landing">First AI-driven SaaS platform for outage detection and prevention in infrastructure and software applications</h2>
                            {/*<p>TODO</p>*/}
                            <Button variant="outline-light" size="lg" onClick={handleWaitlistShow}>Join Our Waitlist</Button>
                        </Col>
                        <Col md={7} className="text-center py-5 position-relative">
                            <img
                                src={`${process.env.PUBLIC_URL}/demo.png`}
                                alt="Desktop"
                                className="popping-image"
                            />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="py-5 text-white">
                <Container>
                    <h2 className="ada-font">{'# what is ada'}</h2>
                    <p>Ada is an AI-driven SaaS platform that integrates with leading cloud providers and software version control systems to continuously monitor and analyze infrastructure and application contexts, proactively detecting and preventing potential outages by identifying and mitigating disruptive changes in real-time</p>
                </Container>
            </section>
            <section className="py-5 bg-dark text-white">
                <Container>
                    <h2 className="ada-font">{'# join our waitlist'}</h2>
                    <p>We are now accepting companies to join our waitlist! Companies that join our waitlist will get periodic updates and early access (beta) to our platform</p>
                </Container>
            </section>
            <section className="py-5 bg-dark text-white">
                <Container>
                    <h2 className="ada-font">{'# join our team'}</h2>
                    <p>Are you passionate about creating groundbreaking projects that will transform software and infrastructure operations? Join our team and be a part of this exciting revolution{' '}
                        <a href="#" onClick={handleJoinTeamShow} className="join-team-link">Join our team!</a>
                    </p>
                </Container>
            </section>
            <footer className="bg-dark text-white text-center py-3">
                <Container>
                    <p>&copy; 2024 Ada Labs LLC. All rights reserved.</p>
                </Container>
            </footer>
            <WaitlistModal show={waitlistModalShow} handleClose={handleWaitlistClose} />
            <JoinTeamModal show={joinTeamModalShow} handleClose={handleJoinTeamClose} />
        </div>
    );
}

export default App;
