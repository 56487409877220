import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useForm, ValidationError } from '@formspree/react';
import '../App.css';

function WaitlistModal({ show, handleClose }) {
    const [state, handleSubmit] = useForm("meojolwz");

    if (state.succeeded) {
        return (
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton className="custom-modal-header">
                    <Modal.Title className="ada-font">Join Our Waitlist</Modal.Title>
                </Modal.Header>
                <Modal.Body className="custom-modal-body ada-font">
                    <p>Thanks for joining!</p>
                    <Button variant="primary" onClick={handleClose} className="custom-submit-button ada-font">
                        Close
                    </Button>
                </Modal.Body>
            </Modal>
        );
    }

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton className="custom-modal-header">
                <Modal.Title className="ada-font">Join Our Waitlist</Modal.Title>
            </Modal.Header>
            <Modal.Body className="custom-modal-body">
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formName" className="mb-4">
                        <Form.Label className="ada-font form-label">Name</Form.Label>
                        <Form.Control type="text" name="name" placeholder="Enter your name" required className="custom-input" />
                        <ValidationError prefix="Name" field="name" errors={state.errors} />
                    </Form.Group>
                    <Form.Group controlId="formCompany" className="mb-4">
                        <Form.Label className="ada-font form-label">Company</Form.Label>
                        <Form.Control type="text" name="company" placeholder="Enter your company name" className="custom-input" />
                        <ValidationError prefix="Company" field="company" errors={state.errors} />
                    </Form.Group>
                    <Form.Group controlId="formJobTitle" className="mb-4">
                        <Form.Label className="ada-font form-label">Job Title</Form.Label>
                        <Form.Control type="text" name="jobtitle" placeholder="Enter your job title" className="custom-input" />
                        <ValidationError prefix="Job Title" field="jobtitle" errors={state.errors} />
                    </Form.Group>
                    <Form.Group controlId="formWorkEmail" className="mb-4">
                        <Form.Label className="ada-font form-label">Work Email</Form.Label>
                        <Form.Control type="email" name="workemail" placeholder="Enter your work email" className="custom-input" />
                        <ValidationError prefix="Work Email" field="workemail" errors={state.errors} />
                    </Form.Group>
                    <Form.Group controlId="formLinkedIn" className="mb-4">
                        <Form.Label className="ada-font form-label">LinkedIn Profile</Form.Label>
                        <Form.Control type="url" name="linkedin" placeholder="Enter your LinkedIn profile URL" required className="custom-input" />
                        <ValidationError prefix="LinkedIn" field="linkedin" errors={state.errors} />
                    </Form.Group>
                    <Form.Group controlId="formAdditionalDetails" className="mb-4">
                        <Form.Label className="ada-font form-label">What are your enterprise use cases or needs you want Ada to perform?</Form.Label>
                        <Form.Control as="textarea" name="usecases" rows={3} placeholder="Enter enterprise needs" className="custom-input" />
                        <ValidationError prefix="Use Cases" field="usecases" errors={state.errors} />
                    </Form.Group>
                    <Button variant="primary" type="submit" disabled={state.submitting} className="custom-submit-button ada-font">
                        Join Waitlist
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default WaitlistModal;
